var render = function render(){
  var _vm$card$owner;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-text', [_c('div', {
    staticClass: "text-h4 text-center mb-12 text-uppercase font-weight-bold"
  }, [_vm._v("Kulcs visszavétele")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6
    }
  }, [_c('v-card', {
    staticClass: "text-center d-flex flex-column justify-center align-center",
    staticStyle: {
      "position": "relative",
      "overflow": "hidden"
    },
    attrs: {
      "width": "420"
    }
  }, [_c('v-overlay', {
    attrs: {
      "value": _vm.loading,
      "absolute": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1), _c('v-card-title', [_vm._v("Kártya")]), _c('v-card-text', [!_vm.card ? _c('div', {
    staticClass: "d-flex flex-column justify-center"
  }, [_c('div', {
    staticClass: "icon-container"
  }, [_c('v-icon', {
    staticClass: "my-6",
    attrs: {
      "size": "96"
    }
  }, [_vm._v("mdi-scan-helper")]), _c('v-icon', {
    staticClass: "center",
    attrs: {
      "size": "48"
    }
  }, [_vm._v("mdi-card-account-details")]), _c('div', {
    staticClass: "spinner center"
  })], 1), _vm._v(" Érintse a kártyát az olvasóhoz! ")]) : _c('div', {
    staticClass: "flex-grow-1 d-flex align-center"
  }, [_c('v-card', [_c('v-avatar', {
    attrs: {
      "width": "100",
      "height": "120",
      "color": "grey ligthen-2",
      "rounded": ""
    }
  }, [_vm.card.photo ? _c('v-img', {
    attrs: {
      "src": 'data:image/png;base64, ' + _vm.card.photo
    },
    on: {
      "click": function ($event) {
        _vm.lightbox = true;
      }
    }
  }) : _c('v-icon', {
    attrs: {
      "size": "80"
    }
  }, [_vm._v("mdi-account")])], 1)], 1), _c('div', {
    staticClass: "ml-5 d-flex flex-column justify-center align-start text-left"
  }, [_c('div', {
    staticClass: "text-h5"
  }, [_vm._v(_vm._s((_vm$card$owner = _vm.card.owner) === null || _vm$card$owner === void 0 ? void 0 : _vm$card$owner.name))]), _c('div', {
    staticClass: "my-2"
  }, [_vm._v("Kártyaszám: " + _vm._s(_vm.card.card_number))]), _c('div', {}, [_vm._v("Típus: " + _vm._s(_vm.card.card_rule_name_hu))])])], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": 6
    }
  }, [_c('v-card', {
    staticClass: "text-center d-flex flex-column justify-center align-center",
    staticStyle: {
      "position": "relative",
      "overflow": "hidden"
    },
    attrs: {
      "width": "420"
    }
  }, [_c('v-overlay', {
    attrs: {
      "value": _vm.loading,
      "absolute": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1), _c('v-card-title', [_vm._v("Kulcs")]), _c('v-card-text', [!_vm.key ? _c('div', {
    staticClass: "d-flex flex-column justify-center"
  }, [_c('div', {
    staticClass: "icon-container"
  }, [_c('v-icon', {
    staticClass: "my-6",
    attrs: {
      "size": "96"
    }
  }, [_vm._v("mdi-scan-helper")]), _c('v-icon', {
    staticClass: "center",
    attrs: {
      "size": "48"
    }
  }, [_vm._v("mdi-key")]), _c('div', {
    staticClass: "spinner center"
  })], 1), _vm._v(" Érintse a kulcsot az olvasóhoz! ")]) : _c('div', {
    staticClass: "flex-grow-1 d-flex align-center"
  }, [_c('v-card', [_c('v-avatar', {
    attrs: {
      "width": "100",
      "height": "120",
      "color": _vm.key.color,
      "rounded": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "80"
    }
  }, [_vm._v("mdi-key")])], 1)], 1), _c('div', {
    staticClass: "ml-5 d-flex flex-column justify-center align-start"
  }, [_c('div', {
    staticClass: "text-h5"
  }, [_vm._v(_vm._s(_vm.key.name))]), _vm.key.remark ? _c('div', {
    staticClass: "my-2"
  }, [_vm._v(_vm._s(_vm.key.remark))]) : _vm._e()])], 1)])], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Megjegyzés")]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "rows": "3",
      "auto-grow": "",
      "filled": "",
      "autofocus": "",
      "hide-details": "",
      "placeholder": "Megjegyzés"
    },
    model: {
      value: _vm.remark,
      callback: function ($$v) {
        _vm.remark = $$v;
      },
      expression: "remark"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm.result ? _c('v-alert', {
    attrs: {
      "color": _vm.result.color,
      "outlined": "",
      "text": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "100",
      "color": _vm.result.color
    }
  }, [_vm._v(_vm._s(_vm.result.icon))]), _c('div', {
    staticClass: "mt-2 text-h5",
    domProps: {
      "innerHTML": _vm._s(_vm.result.message)
    }
  }), _vm.RESULTS.returned === _vm.result ? [_c('v-divider', {
    staticClass: "ma-4"
  }), _c('div', {
    staticClass: "text-h6"
  }, [_vm._v("Megtörtént a kulcs visszavétele?")]), _c('v-btn', {
    staticClass: "ma-4",
    attrs: {
      "color": "error",
      "width": "200",
      "depressed": "",
      "x-large": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-close-thick")]), _vm._v(" NEM ")], 1), _c('v-btn', {
    staticClass: "ma-4",
    attrs: {
      "color": "success",
      "width": "200",
      "depressed": "",
      "x-large": ""
    },
    on: {
      "click": _vm.returned
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-check-bold")]), _vm._v(" IGEN ")], 1)] : _vm._e()], 2) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }